import { get } from 'lodash';
import { captureError } from '../utils/datadog';

function getAnalytics(): undefined | SegmentAnalytics.AnalyticsJS {
  return get(window, 'analytics');
}

function tryRun(
  fn: (analytics: SegmentAnalytics.AnalyticsJS) => void,
  fallback?: () => void,
): void {
  const analytics = getAnalytics();

  if (analytics) {
    try {
      fn(analytics);

      return;
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.log(error, 'Analytics');
      captureError(error, 'AnalyticsHelpers#tryRun');
    }
  }

  if (fallback) {
    try {
      fallback();
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.log(error, 'Analytics');
      captureError(error, 'AnalyticsHelpers#tryRunFallback');
    }
  }
}

export function initAnalytics(): void {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    tryRun((analytics) => {
      analytics.load(import.meta.env.VITE_SEGMENT_KEY);
    });
  }
}

export function onAnalyticsReady(fn: () => void): void {
  tryRun((analytics) => {
    analytics.ready(fn);
  }, fn);
}

export function identifyAnalytics(
  userGUID: string,
  traits?: any,
  options?: any,
): void;
export function identifyAnalytics(userGUID: string, traits?: any): void;
export function identifyAnalytics(...args: any[]): void {
  tryRun((analytics) => {
    analytics.identify(...args);
  });
}

export function trackEvent(
  event: string,
  properties: Record<string, unknown> = {},
  callback?: () => void,
): void {
  properties = { ...properties };

  tryRun((analytics) => {
    analytics.track(event, properties, callback);

    if (import.meta.env.NODE_ENV !== 'production') {
      callback?.();
    }
  });
}

export function trackPage(name: string, callback?: () => void): void {
  tryRun((analytics) => {
    analytics.page(name, callback);

    if (import.meta.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.info('Analytics: Page View', { name });
      callback?.();
    }
  });
}
