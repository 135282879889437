import { SubmissionErrors } from 'final-form';
import createDecorator from 'final-form-focus';
import _ from 'lodash';
import * as React from 'react';
import { Form } from 'react-final-form';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { SignupCarrierType } from '../data/DTOs';
import { trackPage } from '../shared/helpers/AnalyticsHelpers';

const { useState } = React;
const focusOnErrors = createDecorator();

const Wrapper = styled.div`
  min-width: 250px;
  max-width: 500px;
  margin: auto;
  position: absolute;
  width: 100%;
  @media (max-width: 768px) {
    min-width: 100%;
  }
  @media (max-width: 1024px) {
    position: unset;
  }
`;
const Title = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin: 0 0 16px 0;
`;
const Stepper = styled.div`
  height: 5px;
  display: flex;
  width: 100%;
`;
const StepperItem = styled.div<{ active?: boolean }>`
  height: 4px;
  background-color: ${({ active }) => (active ? css`#36B352` : css`#e8ecf0`)};
  flex: 1;
  border-radius: 100px;
  &:not(:last-child) {
    margin-right: 10px;
    flex: 1;
  }
`;
const StyledForm = styled.form`
  position: relative;
`;

export type WizardDataType = SignupCarrierType & {
  carrierData?: any;
  carrierStatus: boolean;
};

interface WizardProps {
  onSumit: (
    data: Record<string, unknown>,
    isRegistered: WizardDataType,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: SignupCarrierType & { referralSource?: string };
  children: JSX.Element[] | JSX.Element;
  routeValues?: string[];
  currentRouteValue?: string;
  onNextRoute: (route: string) => void;
  onFirstRoute: (route: string) => void;
}
interface WizardStepProps {
  children?: JSX.Element | undefined | null;
  title: string;
  render?:
    | ((props: {
        onNext: (values: any) => void;
        values: SignupCarrierType;
        wizardData: WizardDataType;
      }) => JSX.Element)
    | null;
  nextButton?: React.ReactNode;
}

export const Wizard = ({
  initialValues,
  onSumit,
  children,
  routeValues,
  currentRouteValue,
  onNextRoute,
  onFirstRoute,
}: WizardProps) => {
  const initialWizardData = React.useMemo(
    () => ({
      ...initialValues,
      carrierStatus: false,
      carrierData: undefined,
    }),
    [initialValues],
  );
  const [wizardData, setValues] = useState(initialWizardData || {});
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const Children = children instanceof Array ? children : [children];

  const currentStepTitle = Children[currentStepIndex - 1]?.props.title;

  React.useEffect(() => {
    if (currentStepTitle) {
      trackPage(currentStepTitle);
    }
  }, [currentStepTitle]);

  React.useEffect(() => {
    if (
      routeValues &&
      currentRouteValue &&
      !_.isEqual(wizardData, initialWizardData || {})
    ) {
      setCurrentStepIndex(routeValues.indexOf(currentRouteValue) + 1);
    }
  }, [currentRouteValue, routeValues, initialWizardData, wizardData]);

  if (
    routeValues &&
    _.isEqual(wizardData, initialWizardData || {}) &&
    currentRouteValue !== routeValues[0]
  ) {
    onFirstRoute(routeValues[0]);
  }

  const transitions = useTransition(currentStepIndex, (c) => c, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { opacity: 0 },
  });

  const next = (stepValues: any) => {
    if (routeValues && currentRouteValue && onNextRoute) {
      onNextRoute(routeValues[currentStepIndex]);
    }
    setCurrentStepIndex(Math.min(currentStepIndex + 1, Children.length));
    setValues({ ...wizardData, ...stepValues });
  };

  const onSubmit = (stepValues: any) => {
    if (Children.length === currentStepIndex) {
      return onSumit(stepValues, wizardData);
    }
    next(stepValues);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
    >
      {({ handleSubmit, values }) => (
        <StyledForm onSubmit={handleSubmit}>
          {transitions.map(({ item, props, key }) => (
            <animated.div
              key={key}
              style={window.innerWidth > 1020 ? props : {}}
            >
              <Wrapper>
                <Title>
                  Step {item} of {Children.length}:{' '}
                  {Children[item - 1].props.title}
                </Title>
                <Stepper>
                  {Children.map((_, k) => (
                    <StepperItem key={k} active={item - 1 >= k} />
                  ))}
                </Stepper>
                {Children[item - 1].props.render
                  ? Children[item - 1].props.render({
                      onNext: next,
                      values,
                      wizardData,
                    })
                  : Children[item - 1]}
                {Children[item - 1].props.nextButton}
              </Wrapper>
            </animated.div>
          ))}
        </StyledForm>
      )}
    </Form>
  );
};

export function Step({ children = null }: WizardStepProps) {
  return children;
}
