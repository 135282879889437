import { datadogLogs } from '@datadog/browser-logs';

export async function initializeUserMonitor() {
  const { VITE_RELEASE_VERSION, VITE_TARGET } = import.meta.env;

  if ('Cypress' in window || VITE_TARGET === 'local') {
    return;
  }

  return import('@datadog/browser-rum')
    .then(({ datadogRum }) => {
      datadogRum.init({
        applicationId: 'a3f751aa-41ce-40de-ab7c-b07b77cc668a',
        clientToken: 'pub9897cc950ea607a6d6db44104e825a34',
        site: 'datadoghq.com',
        service: 'website-signup',
        version: VITE_RELEASE_VERSION,
        env: VITE_TARGET,
        sessionSampleRate: 5,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    })
    .catch((error) => {
      captureError(error, 'DataDog RUM');
    });
}

const urlMatchers = ['https://api.segment.io', 'https://cdn.segment.com'];

const errorMatchers = [
  'Error sending segment performance metrics',
  'Failed to load Analytics.js',
  'Load failed',
  'Script error.',
  'Network Error',
  'Request aborted',
];

const ignoredErrorMatchers = [
  'ResizeObserver loop limit exceeded',
  'Object Not Found Matching Id',
];

if (!('Cypress' in window) && import.meta.env.NODE_ENV === 'production') {
  const { VITE_RELEASE_VERSION, VITE_TARGET } = import.meta.env;

  datadogLogs.init({
    clientToken: 'pub9897cc950ea607a6d6db44104e825a34',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version: VITE_RELEASE_VERSION,
    env: VITE_TARGET,
    service: 'website-signup',
    forwardConsoleLogs: ['error'],
    beforeSend(log) {
      //Skip all "Failed to fetch" errors
      if (log.http?.status_code === 0) {
        return false;
      }

      if (log.origin === 'console') {
        log.status = 'warn';
        return;
      }

      //silence third-party errors
      if (log.error?.stack?.includes('<anonymous>')) {
        log.status = 'warn';
        return;
      }

      //skip logging loading errors of specific URLs
      if (log.http) {
        for (const urlPart of urlMatchers) {
          if (log.http.url.startsWith(urlPart)) {
            return false;
          }
        }
      }

      // Ignore all Network errors
      if (
        log.error?.origin === 'network' &&
        log.message.includes('Network Error')
      ) {
        return false;
      }

      for (const matcher of ignoredErrorMatchers) {
        if (log.message.includes(matcher)) {
          return false;
        }
      }

      for (const matcher of errorMatchers) {
        if (log.message.includes(matcher)) {
          log.status = 'warn';
          return;
        }
      }

      return undefined;
    },
  });
}

export function getDatadogLogger() {
  return datadogLogs.logger;
}

export function captureError(
  error: unknown,
  source: string,
  context?: Record<string, any>,
) {
  const logger = getDatadogLogger();

  if (typeof error === 'string') {
    logger.error(error, { context, source }, new Error(error));
  } else if (error instanceof Error) {
    logger.error(error.message, { context, source }, error);
  } else {
    logger.error(
      'Unknown Error',
      { error_payload: error, context, source },
      new Error('Unknown Error'),
    );
  }

  if (
    error instanceof Error &&
    (error.name === 'ChunkLoadError' ||
      error.message === "Unexpected token '<'")
  ) {
    setTimeout(() => {
      requestForceReload();
    }, 2000);
  }
}

export function logInfo(message: string, context?: Record<string, any>) {
  const logger = getDatadogLogger();
  logger.info(message, { context });
}

export function logWarning(message: string, context?: Record<string, any>) {
  const logger = getDatadogLogger();
  logger.warn(message, { context });
}

function requestForceReload() {
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      'Failed to load required assets. This can happen either due to bad internet connection or a new version of the website. Reload the page?\n' +
        '(Clear your browser cache and reload the page manually if this error occurs again)',
    )
  ) {
    window.location.reload();
  }
}
