import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import styled from 'styled-components';
import { GetStartedNotes } from '../core/GettingStartedNotes';
import { Modal } from '../core/Modal';
import { TestimonialCardWithImage } from '../core/TestimonialCard';
import { Step, Wizard, WizardDataType } from '../core/Wizard';
import { fetchFeatures, joinCarrier, signupCarrier } from '../data/CarrierAPI';
import { SignupCarrierType } from '../data/DTOs';
import CardIcon from '../shared/assets/card-icon.svg?react';
import TestimonialImage from '../shared/assets/testimonial-1.png';
import TestimonialImage2 from '../shared/assets/testimonial-2.png';
import {
  identifyAnalytics,
  trackEvent,
  trackPage,
} from '../shared/helpers/AnalyticsHelpers';
import { isChromium } from '../shared/helpers/browserHelpers';
import { openSupportChat } from '../shared/helpers/intercomHelpers';
import { parseSearchParams } from '../shared/helpers/URLHelpers';
import { useInitialState } from '../shared/helpers/useInitialState';
import {
  Content,
  ContentLeft,
  ContentLeftInner,
  ContentRight,
  ContentRightInner,
} from '../shared/layout/Content';
import { Header } from '../shared/layout/Header';
import { Button } from '../shared/ui/Button';
import { captureError, logInfo, logWarning } from '../shared/utils/datadog';
import { AdditionalInfo } from './steps/AdditionalInfo';
import { AdditionalInfoLegacy } from './steps/AdditionalInfoLegacy';
import { CreateAccount } from './steps/CreateAccount';
import { VerifyUSDOT } from './steps/VeifyUSDOT';

const CardBody = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin: 45px 0;
`;
const CardFooter = styled.div`
  border-top: 1px solid #e1e5ea;
  padding-top: 25px;
  line-height: 20px;
  color: #6d7480;
`;
const CardQuouteAuthor = styled.div`
  line-height: 24px;
`;
const ModalThankYouText = styled.div`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.35px;
  margin: 20px auto;
  font-weight: 600;
  max-width: 200px;
`;
const ModalMessage = styled.div`
  color: #6a707c;
  text-align: center;
  margin-bottom: 5px;
`;
const ModalMessageBold = styled(ModalMessage)`
  font-weight: 600;
  margin-bottom: 20px;
`;
const ModalReturnButton = styled(Button)`
  margin-top: 48px;
  min-width: 273px;
`;
const StyledContent = styled(Content)`
  min-height: 115vh;
  @media (max-width: 768px) {
    min-height: 100vh;
  }
`;

function formatAPIErrors(
  context: Record<string, unknown>,
): Record<string, string> | undefined {
  const errors: Record<string, string> = {};

  for (const field in context) {
    const error = context[field];
    if (Array.isArray(error)) {
      errors[field] = error.join(', ');
    } else if (typeof error === 'string') {
      errors[field] = error;
    }
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return undefined;
}

export function SignupCarrier({
  match,
  history,
  location,
}: RouteComponentProps<{ step: string }>) {
  if (!match.params.step) {
    history.replace({
      pathname: '/carrier/create-account',
      search: location.search,
    });
  }
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [carrierData, setCarrierData] = React.useState<any>();
  const [features, setFeatures] =
    React.useState<Record<string, boolean | null>>();
  const { search } = useLocation();
  const initialSearchState = useInitialState(search);

  const [joinEmail, setJoinEmail] = React.useState();

  const initialValues = React.useMemo<SignupCarrierType>(() => {
    const searchParams = parseSearchParams(initialSearchState || '');
    return {
      usdot: '',
      first_name: searchParams.first_name || '',
      last_name: searchParams.last_name || '',
      email: searchParams.email || '',
      password: '',
      role: '',
      role_other: '',
      number_of_trucks: 0,
      phoneNumber: {
        iso2: searchParams.phoneISO2 || 'us',
        dialCode: searchParams.phoneDialCode || '1',
        phone: searchParams.phoneNumber || '',
      },
      heard_about_us: '',
      companyType: 'carrier',
      subscriptions: false,
      signing_up_per_broker_request: '',
      broker_name: '',
      referralSource: '',
    };
  }, [initialSearchState]);

  React.useEffect(() => {
    void fetchFeatures().then(({ data }) => {
      setFeatures(data);
    });
  }, []);

  React.useEffect(() => {
    trackPage('Carrier Sign Up');
  }, []);

  const handleSubmit = async (values: any, wizardData: WizardDataType) => {
    if (!captchaToken) {
      logWarning('Carrier missing Captcha token', values);
      // eslint-disable-next-line no-alert
      alert('Failed to verify Captcha. Please try again.');
      return;
    }

    try {
      if (!isSubmitting) {
        setSubmitting(true);
        if (isRegistered) {
          const signupData = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            usdot: values.usdot,
            number_of_trucks: values.number_of_trucks,
            role:
              values.role === 'Other'
                ? values.role_other.toLowerCase()
                : values.role.toLowerCase().replace(/\s/g, '_'),
            phone_number: `+${values.phoneNumber.dialCode}${values.phoneNumber.phone}`,
            heard_about_us:
              values.comment !== ''
                ? values.comment === 'Other'
                  ? values.referralSource
                  : values.comment
                : undefined,
            company_type:
              values.number_of_trucks === '1' ? 'owner_operator' : 'fleet',
            email_subscription: values.subscriptions,
            sms_subscription: values.subscriptions,
            signing_up_per_broker_request: values.perBrokerRequest,
            broker_name: values.broker_name,
            browser: isChromium() ? 'chromium' : 'other',
            cf_turnstile_response: captchaToken,
          };

          if (features?.carrier_signup_updates) {
            delete signupData.signing_up_per_broker_request;
          }

          const { error, user_message, meta } = await joinCarrier(
            signupData,
            carrierData?.guid,
          );
          setSubmitting(false);

          if (meta.code === 200) {
            setJoinEmail(
              user_message
                ? user_message.match(
                    new RegExp(
                      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
                    ),
                  )[0]
                : '',
            );

            identifyAnalytics(values.email, {
              email: values.email,
              name: values.first_name,
              company_name: values.company_name,
            });
            trackEvent('Join request sent', {
              companyType:
                values.number_of_trucks === '1' ? 'owner_operator' : 'fleet',
            });
            logInfo('Carrier Join request sent', {
              ...signupData,
              password: null,
              cf_turnstile_response: null,
            });
          } else {
            logWarning('Carrier join failed', {
              error,
              values: {
                ...signupData,
                password: null,
                cf_turnstile_response: null,
              },
            });

            const validationErrors =
              error.type === 'ValidationError' &&
              !!error.context &&
              formatAPIErrors(error.context);

            if (validationErrors) {
              if (validationErrors.email || validationErrors.phone_number) {
                history.go(-2);
              }

              return validationErrors;
            }

            return {
              [FORM_ERROR]:
                error.user_message ||
                error.dev_message ||
                error.type ||
                'Failed to submit',
            };
          }
        } else {
          const signupData = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            usdot: values.usdot,
            phone_number: `+${values.phoneNumber.dialCode}${values.phoneNumber.phone}`,
            role:
              values.role === 'Other'
                ? values.role_other.toLowerCase().replace(/\s/g, '_')
                : values.role.toLowerCase().replace(/\s/g, '_'),
            signing_up_per_broker_request: values.signing_up_per_broker_request,
            number_of_trucks: values.number_of_trucks,
            interested_in_eld: values.interested_in_eld,
            heard_about_us:
              values.comment !== ''
                ? values.comment === 'Other'
                  ? values.referralSource
                  : values.comment
                : undefined,
            company_type: 'carrier',
            email_subscription: values.subscriptions,
            sms_subscription: values.subscriptions,
            broker_name: values.broker_name,
            browser: isChromium() ? 'chromium' : 'other',
            cf_turnstile_response: captchaToken,
          };

          if (features?.carrier_signup_updates) {
            delete signupData.signing_up_per_broker_request;
          }

          const { error, url, meta } = await signupCarrier(signupData);
          setSubmitting(false);

          if (meta.code === 302) {
            identifyAnalytics(values.email, {
              email: values.email,
              name: values.first_name,
              companyName: values.company_name,
            });

            trackEvent('Sign up submitted', {
              companyName: values.company_name,
              companyType: wizardData.companyType,
            });
            logInfo('Carrier Sign up submitted', {
              ...signupData,
              password: null,
              cf_turnstile_response: null,
            });

            window.location.href = `${url}&next=/tms/loads?utm_source=carrier_signup`;
          } else {
            logWarning('Carrier signup failed', {
              error,
              values: {
                ...signupData,
                password: null,
                cf_turnstile_response: null,
              },
            });

            const validationErrors =
              error.type === 'ValidationError' &&
              !!error.context &&
              formatAPIErrors(error.context);

            if (validationErrors) {
              if (validationErrors.email || validationErrors.phone_number) {
                history.go(-2);
              }

              return validationErrors;
            }

            return {
              [FORM_ERROR]:
                error.user_message ||
                error.dev_message ||
                error.type ||
                'Failed to submit',
            };
          }
        }
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
      setSubmitting(false);
      // eslint-disable-next-line no-alert
      alert('Something went wrong! Please contact support!');
      openSupportChat();
      captureError(e, 'CarrierSignup#submit', {
        ...values,
        password: null,
      });
    }
  };

  return (
    <StyledContent>
      <ContentLeft>
        <Header half={true} type="carrier" />
        <ContentLeftInner>
          <Wizard
            routeValues={['create-account', 'additional-info', 'verify-usdot']}
            currentRouteValue={match.params.step}
            onNextRoute={(route) => {
              history.push({
                pathname: route,
                search: location.search,
              });
            }}
            onFirstRoute={(route) => {
              history.replace({
                pathname: route,
                search: location.search,
              });
            }}
            onSumit={handleSubmit}
            initialValues={initialValues}
          >
            <Step title="Create Account" render={() => <CreateAccount />} />
            <Step
              title="Additional Info"
              render={({ wizardData }) =>
                features?.carrier_signup_updates ? (
                  <AdditionalInfo
                    submitting={isSubmitting}
                    wizardData={wizardData}
                  />
                ) : (
                  <AdditionalInfoLegacy
                    submitting={isSubmitting}
                    wizardData={wizardData}
                  />
                )
              }
            />
            <Step
              title="USDOT Verification"
              render={({ values, wizardData }) => (
                <VerifyUSDOT
                  usdot={values.usdot}
                  wizardData={wizardData}
                  submitting={isSubmitting}
                  onCaptchaTokenChange={(token) => {
                    setCaptchaToken(token);
                  }}
                  onCarrierStatusChange={(status, data) => {
                    setIsRegistered(status);
                    setCarrierData(data);
                  }}
                />
              )}
            />
          </Wizard>
        </ContentLeftInner>
      </ContentLeft>
      <ContentRight>
        <ContentRightInner>
          {match.params.step === 'create-account' ? (
            <>
              <GetStartedNotes />
              <TestimonialCardWithImage
                src={TestimonialImage}
                body={
                  <div style={{ margin: '32px', marginLeft: '80px' }}>
                    <CardIcon />
                    <CardBody>
                      “With Super Dispatch, our cash flow is now 1,000 times
                      quicker!”
                    </CardBody>
                    <CardFooter>
                      <CardQuouteAuthor> Lori W.,</CardQuouteAuthor>
                      <CardQuouteAuthor>
                        Right Way Auto Transport
                      </CardQuouteAuthor>
                    </CardFooter>
                  </div>
                }
              />
            </>
          ) : (
            <TestimonialCardWithImage
              src={TestimonialImage2}
              body={
                <div style={{ margin: '32px', marginLeft: '80px' }}>
                  <CardIcon />
                  <CardBody>
                    “I don’t have to use anything (other than Super Dispatch)…it
                    takes me 6 or 7 minutes now just to cut and copy the VIN
                    numbers, I already have our brokers contacts saved in the
                    system, it’s just easy to use.”
                  </CardBody>
                  <CardFooter>
                    <CardQuouteAuthor> Kevin Parada,</CardQuouteAuthor>
                    <CardQuouteAuthor> JP Auto Transport</CardQuouteAuthor>
                  </CardFooter>
                </div>
              }
            />
          )}
        </ContentRightInner>
      </ContentRight>
      {joinEmail && (
        <Modal
          body={
            <>
              <ModalThankYouText>Thank you!</ModalThankYouText>
              <ModalMessage>For verification email was sent to:</ModalMessage>
              <ModalMessageBold>{joinEmail}</ModalMessageBold>
              <ModalMessage>Please contact account owner.</ModalMessage>
              <ModalReturnButton
                onClick={() =>
                  (window.location.href = 'https://superdispatch.com/')
                }
              >
                Return to Homepage
              </ModalReturnButton>
            </>
          }
        />
      )}
    </StyledContent>
  );
}
