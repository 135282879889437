import { Checkbox } from '@sdkit/checkbox';
import emailSpellChecker from '@zootools/email-spell-checker';
import * as React from 'react';
import { Field, FieldMetaState, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { TermsOfService } from '../../core/TermsOfService';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import {
  composeValidators,
  disallowHtml,
  isValidCompanyName,
  isValidEmail,
  isValidName,
  isValidPhone,
  required,
  validatePassword,
} from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { PasswordField } from '../../shared/ui/PasswordField';
import { PasswordStrength } from '../../shared/ui/PasswordStrength';
import { PhoneField } from '../../shared/ui/PhoneField';
import { TextField } from '../../shared/ui/TextField';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HelperText = styled.span`
  font-size: 16px;
  margin: 5px 0;
  line-height: 20px;
  color: #8f949e;
  white-space: nowrap;
`;

const Link = styled.a`
  color: #0063db;
  cursor: pointer;
  text-decoration: underline;
`;

const validations = {
  firsName: composeValidators([
    isValidName('Please enter a first name'),
    disallowHtml(),
  ]),
  last_name: composeValidators([
    isValidName('Please enter a last name'),
    disallowHtml(),
  ]),
  password: required('Please enter a password'),
  email: isValidEmail('Please enter a valid email'),
  phone: isValidPhone('Please enter a valid phone number'),
  company_name: composeValidators([
    isValidCompanyName('Enter a company name'),
    disallowHtml(),
  ]),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

export function CreateAccount() {
  const { submitErrors, values, submitFailed: isSubmitFailed } = useFormState();

  const suggestion = emailSpellChecker.run({
    email: values.email || '',
  });

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Create Account';
  }, []);

  return (
    <Wrapper>
      <FormGroup>
        <Field name="company_name" validate={validations.company_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Company Name"
              autoFocus={!submitErrors}
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="first_name" validate={validations.firsName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="First Name"
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
        <Field name="last_name" validate={validations.last_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Last Name"
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="email" validate={validations.email}>
          {({ input, meta }) => (
            <FieldWrapper>
              <TextField
                {...input}
                label="Email"
                maxLength={255}
                errorMessage={errors(meta) || submitErrors?.email}
              />
              {suggestion && !(errors(meta) || submitErrors?.email) && (
                <HelperText>
                  Do you mean{' '}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      input.onChange(suggestion.full);
                    }}
                  >
                    {' '}
                    {suggestion.domain}
                  </Link>
                  ?
                </HelperText>
              )}
            </FieldWrapper>
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field
          name="phoneNumber"
          validate={(value) =>
            validations.phone(`+${value.dialCode}${value.phone}`, value.iso2)
          }
        >
          {({ input, meta }) => (
            <PhoneField
              {...input}
              placeholder="Your phone number"
              label="Phone Number"
              separateDialCode={true}
              errorMessage={errors(meta) || submitErrors?.phone_number}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <Field name="password" maxLength={255} validate={validatePassword}>
          {({ input, meta }) => (
            <PasswordField
              {...input}
              label="Password"
              placeholder="Enter 8 characters or more"
              errorMessage={!!meta.error && isSubmitFailed && ' '}
            />
          )}
        </Field>
      </FormGroup>

      <PasswordStrength value={values.password} />

      <FormGroup>
        <Field type="checkbox" name="subscriptions">
          {({ input }) => (
            <Checkbox
              {...input}
              label="I agree to receive SMS and Email messages from Super Dispatch such as the latest offers and deals."
            />
          )}
        </Field>
      </FormGroup>

      <Button size="large" type="submit">
        Continue <StyledArrowForwardIcon />
      </Button>
      <TermsOfService />
    </Wrapper>
  );
}
