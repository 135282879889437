import styled from 'styled-components';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;

const StyledSteps = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledStep = styled.button<{ $isActive: boolean }>`
  height: 4px;
  border-radius: 2px;
  flex-grow: 1;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: ${({ $isActive }) =>
    $isActive ? 'rgba(3, 135, 47, 1)' : 'rgba(225, 229, 234, 1)'};
`;

export function Stepper({
  steps,
  currentStepIndex,
  onStepChange,
}: {
  steps: string[];
  currentStepIndex: number;
  onStepChange: (stepIndex: number) => void;
}) {
  const currentStep = steps[currentStepIndex];

  return (
    <StyledRoot>
      <StyledTitle>
        Step {currentStepIndex + 1} of {steps.length}: {currentStep}
      </StyledTitle>
      <StyledSteps>
        {steps.map((_, index) => (
          <StyledStep 
            type="button"
            disabled={index > currentStepIndex}
            key={index} 
            $isActive={index === currentStepIndex} 
            onClick={() => {
              onStepChange(index);
            }}
          />
        ))}
      </StyledSteps>
    </StyledRoot>
  );
}
