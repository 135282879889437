import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { createGlobalStyle } from 'styled-components';
import { SignupCarrier } from './carrier/SignupCarrier';
import reportWebVitals from './reportWebVitals';
import { SelectProduct } from './SelectProduct';
import { initAnalytics } from './shared/helpers/AnalyticsHelpers';
import { bootstrap } from './shared/utils/bootstrap';
import { ErrorBoundary } from './shared/utils/ErrorBoundary';
import { SignupShipperPage } from './shipper/SignupShipperPage';

bootstrap();

const GlobalStyles = createGlobalStyle`
  * {
    font-family: Helvetica, sans-serif;
    font-size:16px;
  }

  html, body, #root{
    color:#192334;
    padding:0;
    margin:0;
    overflow-x:hidden;
    position:relative;
    @media(max-width:768px){
      height:unset;
    }
  }

  input{
    font-size: 16px !important;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export function App() {
  const fadeIn = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <GlobalStyles />
        <animated.div style={fadeIn}>
          <Switch>
            <Route path="/" exact={true} component={SelectProduct} />
            <Route path="/shipper" exact={true} component={SignupShipperPage} />
            <Route
              path="/carrier/:step?"
              exact={true}
              component={SignupCarrier}
            />
          </Switch>
        </animated.div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
