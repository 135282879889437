import { Button as SDKitButton } from '@sdkit/button';
import * as React from 'react';
import { Field, FieldMetaState, useFormState } from 'react-final-form';
import styled from 'styled-components';
import {
  APIErrorResponse,
  isAPIErrorResponse,
  isCarrierDeactivatedErrorResponse,
  verifyUSDOT,
} from '../../data/CarrierAPI';
import { SignupCarrierType } from '../../data/DTOs';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { CaptchaWidget } from '../../shared/helpers/CaptchaWidget';
import { openSupportChat } from '../../shared/helpers/intercomHelpers';
import { required } from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { TextField } from '../../shared/ui/TextField';
import { logInfo, logWarning } from '../../shared/utils/datadog';
import { sendToWebhook } from '../../shared/webhooks/webhook';

const { useState } = React;

const Wrapper = styled.div`
  margin-top: 32px;
`;
const StyledButton = styled(Button)`
  margin-top: 16px;
`;
const StyledTextField = styled(TextField)`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;
const InfoText = styled.div`
  color: #6a707c;
  font-size: 14px;
  margin-top: 10px;
`;
const PhoneText = styled.a`
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid #c4cdd5;
  padding-bottom: 2px;
  transition: 0.2s ease-in-out;
  &:hover {
    color: #0075ff;
    border-color: #0075ff;
  }
`;
const UserMessage = styled.div<{ background?: string }>`
  background: ${({ background }) => background || '#f3f5f8'};
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #192334;
  margin-top: 32px;
`;
const MessageText = styled.span`
  font-size: 16px;
  line-height: 24px;
`;
const MessageTextSecondary = styled(MessageText)`
  color: #6a707c;
`;
const MessageTextBold = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #192334;
  font-weight: 600;
  margin-top: 16px;
`;
const StyledSDKitButton = styled(SDKitButton)`
  min-height: 40px;
  margin-top: 16px;
  && {
    border: 1px solid #c4cdd5;
  }
`;

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

interface VerifyUSDOTProps {
  usdot: string;
  submitting?: boolean;
  onCaptchaTokenChange: (token: string | null) => void;
  wizardData: SignupCarrierType & { carrierData?: any; carrierStatus: boolean };
  onCarrierStatusChange: (status: boolean, carrierData: any) => void;
}

export function VerifyUSDOT({
  usdot,
  wizardData,
  submitting,
  onCaptchaTokenChange,
  onCarrierStatusChange,
}: VerifyUSDOTProps) {
  const { submitError } = useFormState();
  const [isRegistered, setIsRegistered] = useState(wizardData.carrierStatus);
  const [error, setError] = useState<APIErrorResponse['error']>();
  const [isVerifying, setVerifying] = useState(false);
  const [carrierData, setCarrierData] = useState(wizardData.carrierData);
  const [verifiedUSDOT, setVerifiedUSDOT] = useState(wizardData.usdot);

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Verify USDOT';
  }, []);

  React.useEffect(() => {
    if (import.meta.env.VITE_TARGET === 'production') {
      void sendToWebhook({
        first_name: wizardData.first_name,
        last_name: wizardData.last_name,
        email: wizardData.email,
        phone_number: `+${wizardData.phoneNumber.dialCode}${wizardData.phoneNumber.phone}`,
        email_subscription: wizardData.subscriptions,
        sms_subscription: wizardData.subscriptions,
        role: wizardData.role,
        number_of_trucks: wizardData.number_of_trucks,
        signing_up_per_broker_request: wizardData.signing_up_per_broker_request,
        source: 'CTMS_Website',
      });
    }
  }, [wizardData]);

  const handleUSDOTVerification = async () => {
    if (usdot && !isVerifying) {
      setVerifying(true);

      trackEvent('Verify USDOT clicked');

      const response = await verifyUSDOT(usdot);

      if (isAPIErrorResponse(response)) {
        setError(response.error);
        logWarning('Carrier USDOT verification failed', {
          usdot,
          response,
        });
      }

      if (response.data) {
        logInfo('Carrier USDOT verified', { usdot });
        trackEvent('USDOT verified');

        const isJoin = response.data.carrier_status === 'registered';
        setIsRegistered(isJoin);
        onCarrierStatusChange(isJoin, response.data.carrier);

        if (isJoin) {
          setCarrierData(response.data.carrier);
        } else {
          setCarrierData(response.data);
          onCarrierStatusChange(isJoin, response.data);
        }
        setVerifiedUSDOT(usdot);
        setError(undefined);
      } else {
        setCarrierData(undefined);
        setIsRegistered(false);
        onCarrierStatusChange(false, undefined);
      }
      setVerifying(false);
    }
  };

  const handleContactSupportClick = () => {
    openSupportChat();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isVerifying && handleUSDOTVerification();
    }
  };

  return (
    <Wrapper>
      <Field name="usdot" validate={required('Please enter USDOT')}>
        {({ input, meta }) => (
          <StyledTextField
            {...input}
            autoFocus={true}
            label="USDOT"
            onKeyPress={handleKeyPress}
            type="number"
            readOnly={isVerifying}
            maxLength={255}
            errorMessage={errors(meta)}
            placeholder="Your company USDOT"
          />
        )}
      </Field>

      <InfoText>
        Don&apos;t have USDOT? Call us:{' '}
        <PhoneText href="tel:+1(816)974-7002">+1(816) 974-7002</PhoneText>
      </InfoText>

      {(carrierData || error) && (
        <StyledSDKitButton
          isLoading={isVerifying}
          disabled={usdot === ''}
          type={usdot === '' ? 'submit' : 'button'}
          onClick={handleUSDOTVerification}
          content="Retry Verification"
        />
      )}

      {error &&
        (isCarrierDeactivatedErrorResponse(error) ? (
          <UserMessage background="#FFF9E5">
            <MessageTextSecondary>{error.user_message}</MessageTextSecondary>
            <br />
            <MessageTextBold>{error.context.carrier.name}</MessageTextBold>
            <MessageTextSecondary>
              {error.context.carrier.city}, {error.context.carrier.state}{' '}
              {error.context.carrier.zip} {error.context.carrier.phone_numbers}
            </MessageTextSecondary>
          </UserMessage>
        ) : (
          <UserMessage>{error.user_message}</UserMessage>
        ))}

      {carrierData && (
        <>
          {!isRegistered && (
            <UserMessage>
              <MessageText>
                Great! We will create your account using the information below:
              </MessageText>
              <MessageTextBold>{carrierData.carrier_name}</MessageTextBold>
            </UserMessage>
          )}
          {isRegistered && (
            <UserMessage>
              <MessageText>
                Carrier with this USDOT is already registered. Join this
                carrier?
              </MessageText>
              <MessageTextBold>{carrierData.name}</MessageTextBold>
              <MessageText>{`${carrierData.city}, ${carrierData.state} ${carrierData.zip} ${carrierData.phone_numbers}`}</MessageText>
            </UserMessage>
          )}
        </>
      )}

      <Wrapper>
        <CaptchaWidget onTokenChange={onCaptchaTokenChange} />
      </Wrapper>

      {!carrierData && !error && (
        <StyledButton
          isLoading={isVerifying}
          type={usdot === '' ? 'submit' : 'button'}
          onClick={handleUSDOTVerification}
        >
          Verify <StyledArrowForwardIcon />
        </StyledButton>
      )}

      {typeof submitError === 'string' && (
        <UserMessage background="#FFF9E5">{submitError}</UserMessage>
      )}

      {error && (
        <StyledButton type="button" onClick={handleContactSupportClick}>
          Contact Support
        </StyledButton>
      )}

      {carrierData && (
        <StyledButton
          type="submit"
          disabled={usdot === '' || usdot !== verifiedUSDOT || submitting}
          isLoading={submitting}
        >
          {isRegistered ? 'Request to Join Carrier' : 'Continue'}{' '}
          <StyledArrowForwardIcon />
        </StyledButton>
      )}

      {isRegistered && (
        <StyledButton
          type="button"
          onClick={handleContactSupportClick}
          color={SDKitButton.SECONDARY}
        >
          Contact Support
        </StyledButton>
      )}
    </Wrapper>
  );
}
