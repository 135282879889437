import axios, { AxiosRequestConfig } from 'axios';
import { Carrier, ExistingCarrierResponseDTO } from './DTOs';

const CARRIER_HOST =
  import.meta.env.VITE_API_URL || 'https://staging.superdispatch.org';

export interface APIResponseMeta {
  code: number;
  request_id: string;
}

export interface APIBaseResponse {
  meta: APIResponseMeta;
  user_message: string;
}

export interface APIResponse<TData = unknown> extends APIBaseResponse {
  data: TData;
}

interface CarrierDeactivatedError {
  type: 'CarrierDeactivated';
  context: {
    carrier: {
      guid: string;
      name: string;
      address: string;
      city: string;
      state: string;
      zip: string;
      phone_numbers: string;
    };
  };
  dev_message?: string;
  user_message?: string;
}

export interface APIErrorResponse extends APIBaseResponse {
  error:
    | {
        type?: string;
        context?: unknown;
        dev_message?: string;
        user_message?: string;
      }
    | CarrierDeactivatedError;
}

export function isAPIErrorResponse(
  response: unknown,
): response is APIErrorResponse {
  return (
    typeof response === 'object' &&
    response != null &&
    'meta' in response &&
    'error' in response
  );
}

export function isCarrierDeactivatedErrorResponse(
  error: unknown,
): error is CarrierDeactivatedError {
  return (
    typeof error === 'object' &&
    error != null &&
    'type' in error &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    error.type === 'CarrierDeactivated'
  );
}

function requestCarrierAPI<T>(config: AxiosRequestConfig): Promise<T> {
  const carrierHttpClient = axios.create({
    baseURL: CARRIER_HOST,
  });

  return carrierHttpClient.request<T>(config).then(({ data }) => data);
}

export function verifyUSDOT(usdot: string) {
  return requestCarrierAPI<APIResponse<ExistingCarrierResponseDTO>>({
    method: 'GET',
    url: '/internal/web/carriers/verify-usdot/',
    params: { usdot },
  });
}

export function signupCarrier(data: any) {
  return requestCarrierAPI<any>({
    method: 'POST',
    url: '/internal/web/signup/',
    data,
  });
}

export function joinCarrier(data: Carrier, guid: string) {
  return requestCarrierAPI<any>({
    method: 'POST',
    url: `/internal/web/carriers/${guid}/join/`,
    data,
  });
}

export function fetchFeatures() {
  return requestCarrierAPI<any>({
    method: 'GET',
    url: '/internal/web/features/',
  });
}
